import './App.css';
import * as Realm from "realm-web";
import React, { useEffect, useState } from 'react';
import { ChartLine } from './components/ChartLine';
// import { Row, Col } from 'antd';
import { getMunitesFromOpen, isWeekday } from './Util';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import LoginForm from './components/LoginForm';

function App() {
  const [dbResults, setDBResults] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [collectionName, setCollectionName] = useState("intra2");

  useEffect(() => {
    if (!isLoggedIn) return; // Exit if not logged in

    const fetchData = async () => {
      try {
        const mongo = user.mongoClient("mongodb-atlas");
        const db = mongo.db("bigdata");
        const collection = db.collection(collectionName);
        const results = await collection.find();
        const orderLookup = { "QQQ": 1, "SMH": 3, "IWM": 5, "SPY": 6, "DIA": 8, "TQQQ": 32, "SOXL": 34, "UPRO": 37, "UDOW": 39 };
        results.sort((a, b) => orderLookup[a.symbol] - orderLookup[b.symbol]);
        if (Array.isArray(results)) {
          setDBResults(results);
        } else {
          console.warn("Fetched data is not an array or is empty.");
        }
      } catch (err) {
        console.error("Error fetching data", err);
      }
    };

    // Initial fetch
    fetchData();

    // Determine the interval based on trading hours
    const minutes = getMunitesFromOpen();
    const intervalDuration = isWeekday() && minutes > 0 && minutes < 390 ? 60000 : 600000;
    const intervalId = setInterval(fetchData, intervalDuration);

    // Return a cleanup function that clears the interval
    return () => clearInterval(intervalId);
  }, [isLoggedIn, user, collectionName]); // This will re-run when isLoggedIn changes

  // Handler for logging in
  const handleLogin = async ({ email, password }) => {
    try {
      const app = new Realm.App({ id: "application-0-veevf" });
      const credentials = Realm.Credentials.emailPassword(email, password);
      const loggedInUser = await app.logIn(credentials);
      setUser(loggedInUser);
      setIsLoggedIn(true);
    } catch (err) {
      console.error("Failed to log in", err);
      // You can set error state and show error message to the user
    }
  };


  if (!isLoggedIn) {
    return (
      <LoginForm onLoginSuccess={handleLogin}></LoginForm>
    );
  }

  if (dbResults === null) {
    return <div>Loading data...</div>;
  }



  return (
    <div className="App">
      <div>
        <Button
          variant="contained"
          onClick={() => {
            console.log("Intra2 button clicked");
            setCollectionName("intra2");
          }}
          style={{
            backgroundColor: collectionName === "intra2" ? "blue" : "gray",
          }}
        >
          Intra2
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            console.log("Intra button clicked");
            setCollectionName("intra");
          }}
          style={{
            backgroundColor: collectionName === "intra" ? "blue" : "gray",
            marginLeft: "1rem",
          }}
        >
          Intra
        </Button>
      </div>
      <Grid container spacing={3}> {/* This replaces the Row component */}
        {dbResults.map((val, index) => (
          <Grid item key={index} xs={12} sm={12} md={6} lg={6} xl={6}  > {/* This replaces the Col component */}
            <ChartLine data={val} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default App;
